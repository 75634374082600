var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar"},[_vm._m(0),_c('div',{staticClass:"navbar-center"},[_c('ul',[_vm._m(1),_c('li',[(this.UserRole == 'admin_produit')?_c('a',{attrs:{"href":"/PageListeInventairesArchives"}},[_vm._v(" Inventaires Archivés "),_c('div',{staticClass:"nav-line"})]):_vm._e()]),_c('li',[(this.UserRole == 'admin_produit')?_c('a',{attrs:{"href":"/PageListeInventairesPasVerifies"}},[_vm._v(" Inventaires Importés "),_c('div',{staticClass:"nav-line"})]):_vm._e()]),(this.UserRole == 'admin_produit')?_c('li',[_vm._m(2)]):_vm._e(),(this.UserRole == 'admin_immo')?_c('li',[_vm._m(3)]):_vm._e(),_c('li',[(this.UserRole == 'admin_immo')?_c('a',{attrs:{"href":"/PageListeInventairesImmobiliersPasVerifies"}},[_vm._v(" Inventaires Immobiliers Importés "),_c('div',{staticClass:"nav-line"})]):_vm._e()]),(this.UserRole == 'admin_immo')?_c('li',[_vm._m(4)]):_vm._e(),_vm._m(5)])]),_c('div',{staticClass:"navbar-right"},[_c('button',{staticClass:"logout-button",on:{"click":_vm.logout}},[_c('div',{staticClass:"logoutWord"},[_vm._v("Logout")]),_c('img',{staticClass:"logout-icon",attrs:{"src":require("../assets/exit.png"),"alt":"Logout Icon"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/AfterLoginAdminPage"}},[_c('div',{staticClass:"logo-container"},[_c('div',[_c('img',{staticClass:"logo",attrs:{"src":require("../assets/most icon.png"),"alt":"Logo"}}),_c('div',{staticClass:"logo-text",staticStyle:{"width":"150px","padding-right":"10px"}},[_vm._v("MOST")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/PagePrincipale"}},[_vm._v(" Accueil "),_c('div',{staticClass:"nav-line"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/PageCreationNvArticles"}},[_vm._v(" Nvx Produits "),_c('div',{staticClass:"nav-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/PageListeInventairesImmobiliersArchives"}},[_vm._v(" Inventaires Immobiliers Archivés "),_c('div',{staticClass:"nav-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"/PageCreationNvArticlesImmobiliers"}},[_vm._v(" Nvx Articles Immobiliers "),_c('div',{staticClass:"nav-line"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"/PageListeUsers"}},[_vm._v(" Utilisateurs "),_c('div',{staticClass:"nav-line"})])])
}]

export { render, staticRenderFns }