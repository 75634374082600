import { render, staticRenderFns } from "./PageArchiveInventaires.vue?vue&type=template&id=3722f708&scoped=true&"
import script from "./PageArchiveInventaires.vue?vue&type=script&lang=js&"
export * from "./PageArchiveInventaires.vue?vue&type=script&lang=js&"
import style0 from "./PageArchiveInventaires.vue?vue&type=style&index=0&id=3722f708&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3722f708",
  null
  
)

export default component.exports